import { FormControlDirective, FormControlName, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { IsNotNull, IsNotNullOrEmpty, IsNull, IsZero } from "./type-safe-helpers";

export function ScrollToTop(isBrowser: boolean): void {
    if (isBrowser) {
        window.scrollTo(0, 0);
    }
}

export function ScrollToTopSmooth(isBrowser: boolean, top: number = 0): void {
    if (isBrowser) {
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        });
    }
}

export function ScrollToView(id: string, isBrowser: boolean): void {
    if (isBrowser) {
        let element = document.getElementById(id);

    if (IsNotNull(element)) {
        element.scrollIntoView();
    }
    }
}

export function GetUniqueId(): string {
    function s4(): string {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function IsValidEmail(emailString: string): boolean {
    if (IsNotNullOrEmpty(emailString)) {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(emailString.trim()).toLowerCase());
    }

    return false;
}

export function IsValidWebAddress(webString: string): boolean {
    if (IsNotNullOrEmpty(webString)) {
        let regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        return regex.test(String(webString.trim()).toLowerCase());
    }

    return false;
}

export function IsUrlHostNameExists(url: string): boolean {
    if (IsNotNullOrEmpty(url)) {
        if (url.toString().match("https://www.api.apply4u.co.uk") || url.toString().match("http://stage-api.apply4u.co.uk")) {
            return true;
        }

        if (url.startsWith('https') || url.startsWith('http')) {
            return true;
        }
    }

    return false;
}

export function RemoveImagTag(value: string): string {
    return value.replace(/<img .*?>/g, "");
}

export function GetMiles(meters: number) {
    if (IsZero(meters)) {
        return 0;
    }
    
    return meters * 0.000621371192;
}

export function DataURLtoFile(dataurl: string, filename: string): File {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  export function GetElementById(id: string): HTMLElement {
    let element = document.getElementById(id);

    if (IsNull(element)) {
        element = document.createElement("customElement");
    }

    return element;
  }

  export function RemoveElementById(id: string): HTMLElement {
    let element = document.getElementById(id);
    if (IsNotNull(element)) {
        element.remove();
    }

    return element;
  }

  export function ClickElementById(id: string): void {
    let element = document.getElementById(id);

    if (IsNotNull(element)) {
        element.click();
    }
  }

const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
    this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
    return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
    const result = originFormControlNameNgOnChanges.apply(this, arguments);
    this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
    return result;
};

export function SetFocusOnError(validationForm: UntypedFormGroup): void {
    Object.keys(validationForm.controls).forEach(key => {
        const controlErrors: ValidationErrors = validationForm.get(key).errors;

        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
                (<any>validationForm.get(key))?.nativeElement?.focus();
                return;
            });
        }
    });
}